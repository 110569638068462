.NoMargin {
  margin: 0;
  padding: 0;
}

.Pointer {
  cursor: pointer;
}

.CardWidth-250 {
  width: 100%;
  min-width: 150px;
  max-width: 250px;
}

.BorderRadius-30 {
  border-radius: 30px;
}

.BorderRadius-40 {
  border-radius: 100px;
}

.normalize > h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

.paddingBottom-10 {
  padding-bottom: 1rem;
  padding-top: 10px;
}

.paddingBottom-20 {
  padding-bottom: 2rem;
  padding-top: 10px;
}

.paddingBottom-30 {
  padding-bottom: 3rem;
  padding-top: 10px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 16px;
}

.UpperCase{
    text-transform: uppercase;
    font-size: .8rem;
}

.alignLeft{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
}