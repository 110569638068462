.wrapper-class {
  border: 1px solid rgb(77, 76, 76);
  display: grid;
  grid-template-rows: 48px 1fr;
}

.toolbar-class {
  border: 1px solid rgb(77, 76, 76);
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 0;
}

.toolbar-class * img {
  width: 15px;
}

.editor-class {
  height: 100vh;
  max-height: 600px;
  padding: 0 30px;
}

.customToolbar {
  border: 1px solid transparent;
}
