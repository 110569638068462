@font-face {
  font-family: NunitoSans;
  src: url("./Assets/@fonts/NunitoSans-SemiBold.ttf");
}

@font-face {
  font-family: NunitoSansRegular;
  src: url("./Assets/@fonts/NunitoSans-Regular.ttf");
}

@font-face {
  font-family: NunitoSansBold;
  src: url("./Assets/@fonts/NunitoSans-Bold.ttf");
}

.pagination {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-self: center;
  justify-content: center;
  background: white;
  padding: 15px;
  border-radius: 9999px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  gap: 5px;
}

.pagination > .active {
  color: #03738c;
  border: 1px solid #03738c;
  width: 1.2rem;
  height: 1.3rem;
  border-radius: 50%;
  text-align: center;
}

.next,
.previous {
  margin-top: 2px;
}
